import { graphql } from "gatsby";
import React from "react";
import { useContext } from "react";
import { SEO } from "../components/seo";
import ThemeContext from "../context/ThemeContext";

const DigitalShowroomPage = ({ data }) => {
  const digitalShowroom = data.prismicDigitalShowroom.data;

  const themeState = useContext(ThemeContext);

  const stackCursorUp = (e) => {
    themeState.changeCursorIndex("1");
  };
  const stackCursorDown = (e) => {
    themeState.changeCursorIndex("-1");
  };

  const resetCursor = (e) => {
    themeState.toggleCursorBlendMode();
    themeState.toggleCursorBackground("transparent");
    themeState.scaleCursor(1);
  };

  const shrinkCursor = (e) => {
    themeState.toggleCursorBlendMode("difference");
    themeState.toggleCursorBackground("#fff");
    themeState.shrinkCursor(0.5);
  };

  return (
    <div className="max-w-[1440px] mx-auto mt-[20px] md:mt-[82px]">
      <SEO
        title="Digital Showroom - Morch"
        description="MØRCH IS A FASHION SALES AGENCY FOUNDED BY LOTTE MØRCH IN 1996 AND IS ONE OF THE LEADING FASHION AGENCIES IN SCANDINAVIA."
      />

      <section className="">
        <div
          className="aspect-video md:h-[524px] mx-[17px] md:mx-auto"
          // onMouseEnter={stackCursorDown}
          // onMouseLeave={stackCursorUp}
        >
          <video
            src={digitalShowroom.digital_showroom_video.url}
            className="w-full h-full object-cover"
            autoPlay
            muted
            loop
            playsInline
            data-wf-ignore="true"
            data-object-fit="cover"
            preload="auto"
          ></video>
          <h4 className="uppercase mt-2">{digitalShowroom.title.text}</h4>
          <div
            className="prose mb-2"
            dangerouslySetInnerHTML={{
              __html: digitalShowroom.description.html,
            }}
          ></div>

          <a
            onMouseEnter={shrinkCursor}
            onMouseLeave={resetCursor}
            href={`${digitalShowroom.url.url}`}
            className="mt-2 pb-4"
            target="_blank"
            rel="noreferrer"
          >
            <span className="backdrop">{digitalShowroom.url.url}</span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default DigitalShowroomPage;

export const query = graphql`
  {
    prismicDigitalShowroom {
      data {
        title {
          text
        }
        description {
          html
        }
        url {
          url
        }
        digital_showroom_video {
          url
        }
      }
    }
  }
`;
